// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableFooter_tableFooter__vp13F {\r\n    background-color: #f1f1f1;\r\n    padding: 8px 0px;\r\n    width: 100%;\r\n    font-weight: 500;\r\n    text-align: left;\r\n    font-size: 16px;\r\n    color: #2c3e50;\r\n    border-bottom-left-radius: 15px;\r\n    border-bottom-right-radius: 15px;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\n.TableFooter_button__8VDcj {\r\n    border: none;\r\n    padding: 7px 14px;\r\n    border-radius: 10px;\r\n    cursor: pointer;\r\n    margin-right: 4px;\r\n    margin-left: 4px;\r\n}\r\n\r\n.TableFooter_activeButton__75-h0 {\r\n    color: white;\r\n    background: #185adb;\r\n}\r\n\r\n.TableFooter_inactiveButton__2s4Lj {\r\n    color: #2c3e50;\r\n    background: #f9f9f9;\r\n}", "",{"version":3,"sources":["webpack://./src/components/admin/SuperAdmin/Lottie/TableComponents/TableFooter/TableFooter.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,WAAW;IACX,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;IACf,cAAc;IACd,+BAA+B;IAC/B,gCAAgC;IAChC,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,mBAAmB;AACvB","sourcesContent":[".tableFooter {\r\n    background-color: #f1f1f1;\r\n    padding: 8px 0px;\r\n    width: 100%;\r\n    font-weight: 500;\r\n    text-align: left;\r\n    font-size: 16px;\r\n    color: #2c3e50;\r\n    border-bottom-left-radius: 15px;\r\n    border-bottom-right-radius: 15px;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\n.button {\r\n    border: none;\r\n    padding: 7px 14px;\r\n    border-radius: 10px;\r\n    cursor: pointer;\r\n    margin-right: 4px;\r\n    margin-left: 4px;\r\n}\r\n\r\n.activeButton {\r\n    color: white;\r\n    background: #185adb;\r\n}\r\n\r\n.inactiveButton {\r\n    color: #2c3e50;\r\n    background: #f9f9f9;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableFooter": "TableFooter_tableFooter__vp13F",
	"button": "TableFooter_button__8VDcj",
	"activeButton": "TableFooter_activeButton__75-h0",
	"inactiveButton": "TableFooter_inactiveButton__2s4Lj"
};
export default ___CSS_LOADER_EXPORT___;
