import React from 'react';
import moment from 'moment';
import 'moment-duration-format';
import Pagination from '@material-ui/lab/Pagination';
import { Box } from '@material-ui/core';
import callApi from '../../../utils/call-api';
import { v1 } from '../../../utils/uuid';
import config from '../../../config'
import './styles/Student.scss';
import { SMALL_LESSON_LENGTH } from '../common/timeTable/TimeTableHelper';
import lang from '../../../utils/lang';
import IntlMessages from "../../../utils/IntlMessages";


const classTypes = {
    next: lang('Ближайшее занятие'),
    future: lang('Следующие занятия'),
    conducted: lang('Завершённые занятия')
};
let classType = '';

class Classes extends React.Component {
    state = {
        dialogVisible: false,
        cancelWindowVisible: true,
        isActiveFL: false,
        avatarLink: `${config.imageStubGeneratorUrl}/50x50.png`,
        defaultSkipValue: config.defaultSkipValue,
        defaultLimitValue: 20,
        currentPage: 1,
        lessonsCount: 0,
        lessonsData: [],
        commentLength: config.defaultCommentLength,
        selectedLessonId: '',
        selectedLessonTeacherComment: '',
        maxMarkValue: config.maxMarkValue,
        timeUntilLesson: 0,
    };

    componentDidMount() {
        this.setState({
            isActiveFL: this.props.isOpen
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (Array.isArray(this.props.nextLessonData) && this.props.nextLessonData[0]) {
            const timer = setInterval(() => {
                const newData = Date.now();
                const startDate = this.props.nextLessonData[0].startDate * 1000 || newData;
                const timeUntil = startDate - newData;
                const threeMinutes = 3 * 60 * 1000;

                if (startDate) {
                    this.setState({timeUntilLesson: timeUntil});
                }

                if (timeUntil < threeMinutes) {
                    clearInterval(timer);
                }

                return clearInterval(timer);
            }, 1000);
        }
    }

    ToggleLessonsMenu = () => {
        this.setState({isActiveFL: !this.state.isActiveFL})
    }

    getLessonsData = (e) => {
        const options = {
            skip: this.state.defaultSkipValue + this.state.defaultLimitValue * (+e.target.innerText - 1),
            limit: this.state.defaultLimitValue
        };
        const pageNumber = +e.target.innerText;

        if (this.props.futureLessonsData) {
            options.type = 'future';
        } else if (this.props.conductedLessons) {
            options.type = 'conducted';
        } else {
            console.log(lang('Wrong lessons type'));

            return;
        }

        callApi('student/getLessons', 'POST', options).then(res => {

            if (res && res.data.ok) {
                this.setState({
                    lessonsData: res.data.lessonsData,
                    currentPage: pageNumber,
                    lessonsCount: res.data.lessonsCount
                });
            } else {
                console.log(res.data.message);
            }
        }).catch(e => {
            console.log(e);
        });
    }

    leaveLesson = lessonData => {
        const lessonLengthInMinutes = Math.floor((lessonData.endDate - lessonData.startDate) / 60);

        callApi((lessonData.students.length < 2) ?
                'student/leaveIndividualVirtualLesson' : 'student/leaveLesson',
            'POST',
            {
            virtualLessonId: lessonData.id,
            individualClassType: (lessonLengthInMinutes > SMALL_LESSON_LENGTH) ?
                'individualClass' : 'individualShortClass',
        }).then(res => {
            if (res && res.data.ok) {
                this.props.updateStudent();
            } else {
                alert(res.data.message);
            }
        }).catch(e => {
            console.log(e);
        });
    }

    getGroupsByStudents = (lessonId) => {
        callApi('dashboard/getGroupsByStudents', 'POST', {
            virtualLessonId: lessonId
        }).then(res => {

            if (res && res.data.ok) {
                console.log(res.data);
            } else {
                console.log(res.data.message);
            }
        }).catch(e => {
            console.log(e);
        });
    }

    // activateDialog = (e) => {
    //     this.setState({
    //         dialogVisible: true,
    //         selectedLessonId: e.target.dataset.lessonId,
    //         selectedLessonTeacherComment: e.target.dataset.lessonTeacherComment
    //     })
    // }

    // deactivateDialog = () => {
    //     const inputError = document.getElementById('addLessonCommentInputError');
    //     inputError.innerHTML = '';
    //     inputError.style.color = '';
    //
    //     this.setState({dialogVisible: false})
    // }

    // createStudentMark = (markValue = 0) => {
    //     return Array.from(Array(this.state.maxMarkValue)).map((value, index) => {
    //
    //         return (
    //             <div className={`review-s-rocket ${markValue <= index ? 'no-rocket' : ''}`}/>
    //         );
    //     });
    // }

    getStudentInfo = studentId => {
        const { allStudentsData } = this.props;

        if (!(typeof allStudentsData === 'object')) return {};

        const studentInfo = allStudentsData.find(el => (el && el.id === studentId));

        if (!studentInfo) return {};

        return {
            name: studentInfo.name,
            surname: (typeof studentInfo.surname === 'string') ? studentInfo.surname.substring(0, 1) : '',
            fullName: studentInfo.name + ' ' +  ((typeof studentInfo.surname === 'string') ? studentInfo.surname.substring(0, 1) : ''),
            avatar: studentInfo.avatar,
        };
    }

    prepareLessonLength = lessonData => {
        const lessonLength  = lessonData.endDate - lessonData.startDate;
        const lessonLengthText = (lessonLength < (60 * 35)) ? lang('25 минут') : lang('50 минут');
        return (
            <>{lessonLengthText}</>
        );
    }

    render() {
        let lessonsData = [];
        let lessonsCount = this.state.lessonsCount ? this.state.lessonsCount : this.props.lessonsCount;

        if (this.props.nextLessonData) {
            classType = classTypes.next;
            lessonsData = this.state.lessonsData.length ? this.state.lessonsData : this.props.nextLessonData;
        } else if (this.props.futureLessonsData) {
            classType = classTypes.future;
            lessonsData = this.state.lessonsData.length ? this.state.lessonsData : this.props.futureLessonsData;
        } else {
            classType = classTypes.conducted;
            lessonsData = this.state.lessonsData.length ? this.state.lessonsData : this.props.conductedLessons;
        }

        _('nextLessonData', lessonsData)

        return (
            <div>
                {/*<CommentDialog*/}
                {/*    dialogVisible={this.state.dialogVisible}*/}
                {/*    closeDialog={this.deactivateDialog}*/}
                {/*    addLessonComment={this.addLessonComment}*/}
                {/*    lessonId={this.state.selectedLessonId}*/}
                {/*    comment={this.state.selectedLessonTeacherComment}*/}
                {/*/>*/}
                <div className='finished_classes'>
                    <div data-w-id={v1()}
                         onClick={this.ToggleLessonsMenu}
                         className='finished_classes_title'>
                        <div className='finished_class_txt'>
                            {lang(classType)}
                        </div>
                    </div>
                    <div
                        className={this.state.isActiveFL === true ? 'finished_visible_block' : 'finished_hidden_block'}>
                        {
                            lessonsData.length ?
                                <div className='further_class_info'>
                                    {
                                        lessonsData.map((lessonData, index) => (
    <div key={v1()}>
        <div className='next_class_info_items'>
            <div key={v1()} className='teacher_item'>
                <div>
                    <img
                        className='teacher_photo'
                        src={((lessonData && lessonData.students && lessonData.students.length === 1) ?
                            this.getStudentInfo(lessonData.students[0]).avatar : null )
                            || this.state.avatarLink}
                        alt={lang('Avatar')}
                    ></img>
                </div>
                <div className='teacher_name'>
                    <div className='text_title'>
                        {(lessonData && lessonData.students && lessonData.students.length > 1) ?
                            <IntlMessages id="Групповое занятие" /> :
                            <IntlMessages id="Студент:" />
                        }
                        <br/>
                    </div>
                    <div className='text_item'>
                        {(lessonData && lessonData.students && lessonData.students.length === 1) ?
                            (this.getStudentInfo(lessonData.students[0])).fullName :
                            ''
                        }
                    </div>
                </div>
            </div>
            <div className='class_type'>
                <div className='text_title'>
                    <IntlMessages id="Тип занятия" />:
                    <br/>
                </div>
                <div className='text_item'>
                    {lessonData.students.length > 1 ? <IntlMessages id="Мини-группа" /> : <IntlMessages id="VIP-занятие" />}
                    <br/>
                    {lessonData.students.length > 1 ? `${lang("на")} ${lessonData.students.length} ${lang("человек")}` : null}
                </div>
            </div>
            {/*<div className='class_subject'>*/}
            {/*    <div className='text_title'>*/}
            {/*        {lang('Предполагаемая тема')}:*/}
            {/*        <br/>*/}
            {/*    </div>*/}
            {/*    <div className='text_item'>*/}
            {/*        {lessonData.name}*/}
            {/*        <br/>*/}
            {/*        {lessonData.lessonName}*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className='class_duration'>
                <div className='text_title'>
                    <IntlMessages id="Продолжительность" />
                    <br/>
                    <IntlMessages id="занятия" />:
                    <br/>
                </div>
                <div className='text_item'>
                    {this.prepareLessonLength(lessonData)}
                </div>
            </div>
            <div className='class_review'>
                <div className='text_title'>
                    <IntlMessages id="Дата начала" />:
                </div>
                <div className='text_item'>
                    {moment(lessonData.startDate * 1000).format('DD.MM.YYYY')}
                </div>
                <div className='text_item'>
                    {moment(lessonData.startDate * 1000).format('HH:mm')}
                </div>
            </div>
            {(() => {

                    if (this.props.conductedLessons) {

                        return (
                            <div className='comment_review_title'>
                                {lessonData.studentsMarks > 0 ? <IntlMessages id="Оценка за урок:" /> : null}
                                <div className='review-s-rockets'>
                                    {
                                        lessonData.studentsMarks > 0 ?
                                            Array.from(Array(this.state.maxMarkValue)).map((value, index) =>
                                                <div className={`review-s-rocket ${lessonData.studentsMarks <= index ? 'no-rocket' : ''}`}/>
                                            ) :
                                            <div className='no-marks-comment-review'/>
                                    }
                                </div>
                            </div>
                        )
                    } else if (this.state.timeUntilLesson > 3 * 60 * 1000 || !lessonData.isEnable) {

                        return (
                            <div data-w-id={v1()} className='countdown'>
                                <div className='teacher_review_title'>
                                    <IntlMessages id="До начала урока" />:
                                </div>
                                <div className='text_item'>
                                    {`~${moment.duration(this.state.timeUntilLesson / 1000, 'seconds')
                                        .format(`dd ${lang('д.')} hh ${lang('ч.')} mm ${lang('м.')} ss ${lang('с.')}`)}`}
                                </div>
                                <div className='cancel_lesson_btn'
                                    onClick={() => {
                                        this.leaveLesson(lessonData);
                                    }}
                                >
                                    <span className='cancel_lesson_txt'>
                                        <IntlMessages id="Отменить занятие" />
                                    </span>
                                </div>
                            </div>
                        )
                    } else {

                        return (
                            <div
                                className='comment comment_review_title comment_review_title_student'
                                onClick={() => {
                                    window.location.href = `/brand/vl?id=${lessonData.id}`
                                }}
                            >
                                <IntlMessages id="Войти" />
                            </div>
                        )
                    }
                }
            )()}
        </div>
        {
            index !== lessonsData.length - 1 ?
                <div className='line'/> :
                null
        }
    </div>
))
                                    }
                                </div> :
                                null
                        }
                        {
                            classType !== classTypes.next && this.props.lessonsCount > this.state.defaultLimitValue ?
                                <Box display="flex" justifyContent="center">
                                    <Pagination
                                        count={Math.ceil(lessonsCount / this.state.defaultLimitValue)}
                                        page={this.state.currentPage}
                                        onChange={this.getLessonsData}
                                    />
                                </Box> :
                                null
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Classes;
