let DomainForBack = (window.location.hostname === 'epicschool.ru') ?
    'https://epicschool.ru' : 'https://epiclang.net';

let DomainForFront = (window.location.hostname === 'epicschool.ru') ?
    'https://epicschool.ru' : 'https://epiclang.net';

let SockerUri = (window.location.hostname === 'epicschool.ru') ?
    'wss://epicschool.ru/socket.io' : 'wss://epiclang.net/socket.io';

console.log('window.location.hostname', window.location.hostname)

if (window.location.hostname === '162.19.158.73') {
    DomainForBack = 'http://162.19.158.73:3553';
    DomainForFront = 'http://162.19.158.73:1337';
    SockerUri = 'http://162.19.158.73:3215';
}

if (window.location.hostname === 'epiclang.studio') {
    DomainForBack = 'https://epiclang.studio';
    DomainForFront = 'https://epiclang.studio';
    SockerUri = 'wss://epiclang.studio/socket.io';
}

if (window.location.hostname === 'www.epiclang.studio') {
    DomainForBack = 'https://epiclang.studio';
    DomainForFront = 'https://epiclang.studio';
    SockerUri = 'wss://epiclang.studio/socket.io';
}

global.authUrl = '/enter';

export default {
    filePathSubstLength: (window.location.hostname === 'epicschool.ru') ? 20 : 19,
    DomainForFront,
    path: '',
    SOCKET_URI: SockerUri,
    API_URI: DomainForBack + '/backend/api/v1',

    defaultSkipValue: 0,
    defaultSkipStepValue: 200,
    defaultLimitValue: 200,
    maxLimitValue: 1000,

    imageStubGeneratorUrl: DomainForFront + '/icons',

    defaultCommentLength: 256,

    maxMarkValue: 5, //from 1 to 5

    imgUrlFromBD: DomainForFront + '/imgs/',
    lottieUrlFromBD: DomainForFront + '/lottie/',

    supportedLanguages: {//must be like in DB, becuse using not id
        en: 'English'
    }
};
